import Plotly, { Frame, Layout, PlotData } from "plotly.js";
import { useEffect, useMemo, useState } from "react";
import Plot from "react-plotly.js";
import ReactDOMServer from "react-dom/server";

const TICK_STEP = 200000;
const DEFAULT_TITLE = "Title";
const DEFAULT_HEIGHT = 500;
const DEFAULT_WIDTH = undefined;

type PlotlyBarChartProps = {
  data: Record<string, Record<string, number>>;
  title?: string;
  height?: number;
  width?: number;
  tickStep?: number;
};

const PlotlyBarChart = ({
  data,
  title = DEFAULT_TITLE,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  tickStep = TICK_STEP,
}: PlotlyBarChartProps) => {
  const [barTrace1, setBarTrace1] = useState<PlotData | null>(null);
  const [barTrace2, setBarTrace2] = useState<PlotData | null>(null);
  const [lineTrace, setLineTrace] = useState<PlotData | null>(null);

  const keys = useMemo(() => Object.keys(data), [data]);

  const cellValues: (string | JSX.Element)[][] = useMemo(() => {
    if (!barTrace1?.y || !barTrace2?.y || !lineTrace?.y) return [];

    return Array.from({ length: keys.length + 1 }, (_, i) => {
      if (i === 0) {
        return ["Due for the month", "Collected", "Collection rate"];
      }
      return [
        `RM${(barTrace1.y[i - 1] as number)?.toLocaleString() || "0"}`,
        `RM${(barTrace2.y[i - 1] as number)?.toLocaleString() || "0"}`,
        `${((lineTrace.y[i - 1] as number) * 100).toFixed(2) || "0"}%`,
      ];
    });
  }, [barTrace1, barTrace2, lineTrace, keys]);

  useEffect(() => {
    const trace1 = {
      x: keys,
      y: prepareData(data)[0],
      texttemplate: "%{label}<br>RM%{value}", // Add RM to the value
      name: "Due for the month",
      type: "bar",
    };

    const trace2 = {
      x: keys,
      y: prepareData(data)[1],
      texttemplate: "%{label}<br>RM%{value}", // Add RM to the value
      name: "Collected",
      type: "bar",
    };

    const trace3 = {
      x: keys,
      y: prepareData(data)[2],
      type: "scatter",
      yaxis: "y2",
      mode: "lines",
      line: {
        width: 4,
        color: "#999999",
        smoothing: 10,
      },
      name: "Collection rate",
    };

    setBarTrace1(trace1 as PlotData);
    setBarTrace2(trace2 as PlotData);
    setLineTrace(trace3 as PlotData);
  }, [data]);

  return (
    <Plot
      data={[
        barTrace1 as PlotData,
        barTrace2 as PlotData,
        { ...lineTrace } as PlotData,
        {
          type: "table",
          draggable: false,
          header: {
            values: ["", ...keys],
            // Making the cell fill transparent to see tick value behind
            fill: {
              color: Array.from({ length: keys.length }, (_, i) =>
                i === 0 ? "transparent" : "white"
              ),
            },
            // To remove border
            line: {
              width: Array.from({ length: keys.length }, (_, i) =>
                i === 0 ? 0 : 1
              ),
            },
          },
          columnwidth: [
            ...Array.from({ length: keys.length }, (_, i) =>
              i === 0 ? 150 : 100
            ),
          ],
          domain: { x: [0.05, 1], y: [0.0, 0.3] },
          cells: {
            align: ["left"],
            values: cellValues,
          },
        } as Partial<PlotData>,
      ]}
      layout={
        {
          dragmode: false,
          margin: { l: 120, r: 120 },
          height: height,
          width: width,
          title: {
            text: title,
            font: {
              family: "PP Neue Machina",
              size: 16,
            },
          },
          barmode: "group",
          bargap: 0.5,
          showlegend: true,
          legend: {
            itemclick: false,
            itemdoubleclick: false,
            // x: 0, // Position the legend at the far left
            x: -0.35,
            y: 0.1,
            // orientation: "v", // Vertical orientation for the legend
            // borderwidth: 1,
            // xanchor: "center",
          },
          yaxis: {
            tickformat: ",", // Format as number with commas
            tick0: 0, // Starting point
            range: [0, generateMax(prepareData(data)[0], tickStep)],
            dtick: tickStep, // Tick interval
            ticklabelstandoff: 15, // Distance between tick labels and the axis
            domain: [0.3, 1], // Ensures the axis is positioned within this range
            gridcolor: "#b3b3b3", // Grid line color for y-axis
            gridwidth: 1, // Grid line width for y-axis
          },
          yaxis2: {
            tickformat: ".2%", // Format as percentage with 2 decimal points
            side: "right", // Place y2 on the right
            overlaying: "y", // Overlay y2 on y
            tick0: 0,
            dtick: 0.1, // Tick interval (for percentages)
            ticklabelstandoff: 15, // Same standoff to align with yaxis
            domain: [0.3, 1], // Same domain to align with yaxis
            range: [0, 1], // Ensure the range is set for percentage
            showgrid: false,
            zeroline: false,
          },
          xaxis: {
            domain: [0.2, 1],
          },
          grid: {
            rows: 2,
            columns: 1,
            pattern: "independent",
          },
          font: {
            family: "Motiva Sans",
          },
        } as Partial<Layout>
      }
      config={{
        displaylogo: false, // Remove the Plotly logo
        responsive: true,
        staticPlot: true,
      }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

const prepareData = (_data: Record<string, Record<string, any>>) => {
  return Object.entries(_data).reduce<Array<Array<number>>>((acc, curr) => {
    const values = Object.values(curr[1]);
    if (acc.length > 0) {
      acc[0].push(values[0]);
      acc[1].push(values[1]);
      acc[2].push(values[2]);
    } else {
      acc[0] = [values[0]];
      acc[1] = [values[1]];
      acc[2] = [values[2]];
    }

    return acc;
  }, []);
};

const generateMax = (values: number[] = [], tickStep: number) => {
  const currentMax = Math.max(...values);
  const remainder = currentMax % tickStep;

  return remainder === 0 ? currentMax : currentMax - remainder + tickStep;
};

export default PlotlyBarChart;
