import React from "react";
import PropTypes from "prop-types";
// import Logo from "../assets/image/swinburne.svg";
import Logo from "../assets/image/rajang logo.png";
import { Image } from "antd";
import { useNavigate } from "react-router";

function Default(props: any) {
  const navigate = useNavigate();
  return (
    <div className="no-access-container">
      <div className="hexlock-container">
        <div className="noaccess-icon">
          <Image
            preview={false}
            width={"185px"}
            className="home-logo"
            src={Logo}
            alt="Logo"
          />
        </div>
      </div>
      <span
        style={
          !props.ismobile
            ? { fontSize: "20px", lineHeight: "30px", textAlign: "center" }
            : {
                fontSize: "20px",
                lineHeight: "30px",
                textAlign: "center",
              }
        }
      >
        {props.text ? props.text : "Page Not Ready. "} {props.break && <br />}
        <span>
          Head back <a onClick={() => navigate("/report_generation")}>home.</a>
        </span>
      </span>
      {props.subtext ? (
        <span
          style={
            !props.ismobile
              ? { fontSize: "1.4em", lineHeight: "1", textAlign: "center" }
              : {
                  fontSize: "1.2em",
                  lineHeight: "1",
                  textAlign: "center",
                }
          }
        >
          {props.subtext}
        </span>
      ) : null}
    </div>
  );
}

Default.propTypes = { params: PropTypes.any };

export default Default;
