import axios from "axios";
import {
  url,
  AuthHeader,
  serverConnection,
  LoginAuthHeader,
} from "../../utils/_exports";
import Cookies from "js-cookie";
const apiURL = "ka-insight";

// // policy GET route
// export const getExcelList = () => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${serverConnection}/${apiURL}`, { ...AuthHeader })
//       .then((_data: any) => {
//         if (_data?.data && _data.data.length) {
//           resolve(_data?.data);
//         } else {
//           resolve(null);
//         }
//       })
//       .catch((e: any) => {
//         reject(e);
//       });
//   });
// };

// // policy GET route by policy id
// export const getExcelListById = (_id: string) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${serverConnection}/${apiURL}/`, {
//         params: _id,
//         ...AuthHeader,
//       })
//       .then((_data: any) => {
//         if (_data?.data.data.data && _data.data.data.data.length) {
//           resolve(_data?.data.data.data);
//         } else {
//           resolve(null);
//         }
//       })
//       .catch((e: any) => {
//         reject(e);
//       });
//   });
// };

// // loan GET route by selected field
// export const getExcelListByField = (param: any) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${serverConnection}/${apiURL}`, {
//         params: param,
//         ...AuthHeader,
//       })

//       .then((_data: any) => {
//         if (_data?.data) {
//           resolve(_data?.data);
//         } else {
//           resolve(null);
//         }
//       })
//       .catch((e: any) => {
//         reject(e);
//       });
//   });
// };

// policy POST route
export const loginAcc = (data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${serverConnection}/${apiURL}/login`, data, {
        headers: LoginAuthHeader,
        withCredentials: true,
      })
      .then((_data: any) => {
        resolve(_data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

export const validateRoute = () => {
  // console.log("AuthHeader", AuthHeader);
  return new Promise((resolve, reject) => {
    axios
      .get(`${serverConnection}/${apiURL}/token/validate`, {
        ...AuthHeader,
        withCredentials: true,
      })
      .then((_data) => {
        resolve(_data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// to save policy file
export const updateExcelFileItem = (_ids: Array<any>, data: any) => {
  const _data = {
    query: { _id: _ids[0] },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios
      // .put(`${url}/${apiURL}/`, _data, AuthHeader)
      .put(`${serverConnection}/${apiURL}/saveFile`, _data, AuthHeader)
      .then((_data: any) => {
        //console.log(_data);
        resolve(_data?.data?.data?.data);
      })
      .catch((e: any) => {
        //console.log(e);
        reject(e);
      });
  });
};

// policy PUT route
export const updateExcelItem = (data: Array<any>) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${serverConnection}/${apiURL}`, data, AuthHeader)
      .then((_data: any) => {
        resolve(_data?.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// policy DELETE route
export const deleteExcelItem = (_id: any) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${serverConnection}/${apiURL}`, {
        params: _id,
        ...AuthHeader,
      })
      .then((_data: any) => {
        resolve(_data?.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};
