/**
 * Utilities for minor functions
 */

import axios from "axios";
import { AuthHeader } from "./_exports";
import { Tenant } from "../App";
import { useLocation } from "react-router";
import { getLookupItem } from "./lookup_list";
import { Form } from "antd";
import path from "path";

const url = process.env.REACT_APP_SERVER_URL as string;

export const checkMobile = () => {
	let check = false;
	// eslint-disable-next-line
	(function (a) {
		// if (
		// 	/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
		// 		a
		// 	) ||
		// 	/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
		// 		a.substr(0, 4)
		// 	)
		// )

		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikim|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor);
	return check;
};

export const clearCookie = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/data/logout`, {
				withCredentials: true,
			})
			.then((res: any) => {
				//console.log("clearCookies function: ", res);
				resolve(res);
			})
			.catch((err: any) => {
				//console.log(err);
				reject(err);
			});
	});
};

// export const logout = (autoLogout: boolean = false) => {
//   const LogoutURL: any = url + "/ka-insight/logout";
//   // const login_type = localStorage.getItem("loginType");
//   //   clearCookie();
//   localStorage.removeItem("accessToken");
//   localStorage.removeItem("idToken");
//   localStorage.removeItem("loginType");
//   localStorage.removeItem("redirectURL");
//   localStorage.removeItem("selectedFilters");
//   // if (autoLogout) {
//   // 	window.location.href = `/`;
//   // } else {
//   // 	if (login_type === "azure_ad") {
//   // 		window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURI(
//   // 			LogoutURL
//   // 		)}`;
//   // 	} else {
//   // 		window.location.href = `/`;
//   // 	}
//   // }
//   window.location.href = `/`;
// };

export const logout = async (autoLogout: boolean = false) => {
	const LogoutURL = `${url}/ka-insight/logout`;
	const login_type = localStorage.getItem("loginType");

	// Clear local storage
	// localStorage.removeItem("accessToken");
	// localStorage.removeItem("idToken");
	// localStorage.removeItem("loginType");
	// localStorage.removeItem("redirectURL");
	// localStorage.removeItem("selectedFilters");

	// Redirect based on login type
	if (autoLogout) {
		try {
			// Make an Axios request to the logout URL
			await axios.get(LogoutURL, { withCredentials: true });
		} catch (error) {
			console.error("Error logging out:", error);
		}
		window.location.href = `/`;
	} else {
		// if (login_type === "azure_ad") {
		//   window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURI(
		//     LogoutURL
		//   )}`;
		// } else {
		try {
			// Make an Axios request to the logout URL
			await axios.get(LogoutURL, { withCredentials: true });
		} catch (error) {
			console.error("Error logging out:", error);
		}
		window.location.href = `/`;
		// }
	}
};

export const checkRoles = (userRoles: any, menuRoles: any) => {
	if (userRoles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
		return true;
	} else if (menuRoles?.length === 0 || !menuRoles) {
		return true;
	} else {
		return menuRoles?.some((element: any) => {
			if (userRoles?.includes(element)) {
				return true;
			}
		});
	}
};

export const checkPrivilege = (userRoles: any, requiredRoles: any) => {
	if (userRoles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
		return true;
	} else if (requiredRoles?.length === 0 || !requiredRoles) {
		return true;
	} else {
		return requiredRoles?.some((element: any) => {
			if (userRoles?.includes(element)) {
				return true;
			}
		});
	}
};

// returns true if same and false if not
export const deepCompare = (prevState: any, currState: any) => {
	// Check if both objects are the same type
	if (typeof prevState !== typeof currState) {
		return false;
	}

	// Handle simple data types and null
	if (prevState === currState) {
		return true;
	}

	// Check for null or undefined
	if (
		prevState === null ||
		currState === null ||
		prevState === undefined ||
		currState === undefined
	) {
		return false;
	}

	// Check if both are arrays
	if (Array.isArray(prevState) && Array.isArray(currState)) {
		if (prevState.length !== currState.length) {
			return false;
		}
		for (let i = 0; i < prevState.length; i++) {
			if (!deepCompare(prevState[i], currState[i])) {
				return false;
			}
		}
		return true;
	}

	// Check if both are objects
	if (typeof prevState === "object" && typeof currState === "object") {
		const keys1 = Object.keys(prevState);
		const keys2 = Object.keys(currState);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (let key of keys1) {
			if (!deepCompare(prevState[key], currState[key])) {
				return false;
			}
		}
		return true;
	}

	return false;
};

export const getAllNodes = (menus: any, sidemenu: boolean = false) => {
	const endNodes: any = [];
	const traverse = (mItem: any, parentRoute: string = "") => {
		if (!mItem.children || mItem.children.length === 0) {
			// Node has no children, it is an end node
			endNodes.push({
				...mItem,
				path: `${parentRoute}/${mItem?.route?.link}`,
			});
		} else {
			// Node has children, recursively traverse each child
			mItem.children.forEach((child: any) => {
				traverse(child, `${parentRoute}/${mItem?.route?.link}`);
			});
		}

		// include side menu items
		if (sidemenu) {
			if (mItem?.sidebarmenu || mItem?.sidebarmenu?.length > 0) {
				mItem?.sidebarmenu?.forEach((menu: any) => {
					if (menu?.children || menu?.children?.length > 0) {
						menu?.children?.forEach((sideChild: any) => {
							traverse(sideChild, `/${mItem?.route?.link}`);
						});
					}
				});
			}
		}
	};

	menus.forEach((mItem: any) => {
		traverse(mItem);
	});
	return endNodes;
};

export const getAlertStyle = (type: any) => {
	// Define styles based on alert type
	switch (type) {
		case "success":
			return {
				backgroundColor: "rgb(255, 255, 255)",
				borderColor: "rgb(0, 0, 0, 0.85)",
			};
		case "info":
			return {
				backgroundColor: "rgb(255, 255, 255)",
				borderColor: "rgb(0, 0, 0, 0.85)",
			};
		case "warning":
			return {
				backgroundColor: "rgb(255, 255, 255)",
				borderColor: "rgb(0, 0, 0, 0.85)",
			};
		case "error":
			return {
				backgroundColor: "rgb(255, 255, 255)",
				borderColor: "rgb(0, 0, 0, 0.85)",
			};
		default:
			return {}; // Default style if the type is not recognized
	}
};

export const formatSentence = (string: any) => {
	// Remove extra white spaces and split the sentence into an array of words
	let words = string.trim().split(/\s+/);

	let formattedSentence = "";
	for (let i = 0; i < words.length; i++) {
		// Capitalize the first word and words after a full stop
		const lastChar = words[i - 1]
			? words[i - 1].charAt(words[i - 1].length - 1)
			: "";
		const terminators = [".", "!", "?", ";"]; // You can add more sentence terminators here

		if (i === 0 || terminators.includes(lastChar)) {
			formattedSentence +=
				words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
		} else {
			formattedSentence += words[i].toLowerCase();
		}

		// Add space between words, except for the last word
		if (i !== words.length - 1) {
			formattedSentence += " ";
		}
	}

	// Add a period at the end if it's not already there
	if (!formattedSentence.endsWith(".")) {
		formattedSentence += ".";
	}

	return formattedSentence;
};

export const getInitials = (name: any, numberSplice: number) => {
	if (name) {
		// Split the name into words
		const words = name.split(" ");

		// Initialize an empty string to store the initials
		let initials = "";

		// Iterate through the words and get the first letter of each
		for (let i = 0; i < words.length; i++) {
			if (words[i][0]) {
				initials += words[i][0].toUpperCase();
			}
		}

		// setInitialsColor(alphabeticalColors[initials[0]]);

		return numberSplice ? initials.slice(0, numberSplice) : initials;
	} else {
		return "";
	}
};

export const alphabeticalColors: any = {
	A: { fontColor: "#432818", backgroundColor: "#FF9A8B" },
	B: { fontColor: "#4A4829", backgroundColor: "#EDE275" },
	C: { fontColor: "#2E4A28", backgroundColor: "#8FE275" },
	D: { fontColor: "#391846", backgroundColor: "#A68DFF" },
	E: { fontColor: "#2C2C2C", backgroundColor: "#D9D9D9" },
	F: { fontColor: "#6D2C7D", backgroundColor: "#FFB1E6" },
	G: { fontColor: "#4A6828", backgroundColor: "#D2FFA2" },
	H: { fontColor: "#2C6C6C", backgroundColor: "#8DE1E1" },
	I: { fontColor: "#D94E2C", backgroundColor: "#FFA47D" },
	J: { fontColor: "#BF4227", backgroundColor: "#FFA25B" },
	K: { fontColor: "#55383D", backgroundColor: "#FFD2D8" },
	L: { fontColor: "#4C4C4C", backgroundColor: "#F0F0F0" },
	M: { fontColor: "#2D2828", backgroundColor: "#FF8D8D" },
	N: { fontColor: "#6C3A2C", backgroundColor: "#FFA37D" },
	O: { fontColor: "#614A22", backgroundColor: "#FFCDA5" },
	P: { fontColor: "#2D536D", backgroundColor: "#A7D4FF" },
	Q: { fontColor: "#503A3E", backgroundColor: "#FFA5A5" },
	R: { fontColor: "#A53B2C", backgroundColor: "#FF8D75" },
	S: { fontColor: "#6D5E2C", backgroundColor: "#FFE2A5" },
	T: { fontColor: "#2C6D47", backgroundColor: "#8DE7B5" },
	U: { fontColor: "#573B2C", backgroundColor: "#FFA07D" },
	V: { fontColor: "#2C5F6D", backgroundColor: "#8DC7FF" },
	W: { fontColor: "#6D2C6B", backgroundColor: "#FFB3E1" },
	X: { fontColor: "#4C6D2C", backgroundColor: "#C7FFA2" },
	Y: { fontColor: "#562C6D", backgroundColor: "#FFB5E7" },
	Z: { fontColor: "#2C6D61", backgroundColor: "#8DE7D1" },
};

// export const daysCalculation = (startDateData: any, endDateData: any) => {
//     return new Promise((resolve, reject) => {
//         getLookupItem("workday").then((workday_data: any) => {
//             getLookupItem("holiday").then((holiday_data: any) => {
//                 const offday: any[] = [];
//                 workday_data.forEach((element: any) => {
//                     if (element.workperiod == "offday") {
//                         offday.push(element.day_code);
//                     }
//                 });

//                 const holidays: any[] = [];
//                 holiday_data.forEach((element: any) => {
//                     holidays.push(new Date(element.ph_date).toISOString().split("T")[0]);
//                 });

//                 let workingDays = 0;

//                 const startDate = new Date(new Date(startDateData).setHours(0, 0, 0, 0));
//                 const endDate = new Date(new Date(endDateData).setHours(0, 0, 0, 0));
//                 const diffDays =
//                     Math.ceil(Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

//                 for (let i = 0; i < diffDays; i++) {
//                     const currentDate = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
//                     const currentDateISO = currentDate.toISOString().split("T")[0];
//                     const dayOfWeek = currentDate.getDay();
//                     if (!offday.includes(dayOfWeek) && !holidays.some((holiday) => holiday === currentDateISO)) {
//                         // Exclude Sunday (0), Saturday (6) and holidays
//                         workingDays++;
//                     }
//                 }
//                 resolve(workingDays);
//             });
//         });
//     });
// };

export const daysCalculation = (
	workday_data: any,
	holiday_data: any,
	startDateData: any,
	endDateData: any,
	half_days: any
) => {
	const offday: any[] = [];
	workday_data?.forEach((element: any) => {
		if (element.workperiod == "offday") {
			offday.push(element.day_code);
		}
	});

	const holidays: any[] = [];
	holiday_data?.forEach((element: any) => {
		holidays.push(new Date(element.ph_date).toISOString().split("T")[0]);
	});

	let workingDays = 0;

	const startDate = new Date(new Date(startDateData).setHours(0, 0, 0, 0));
	const endDate = new Date(new Date(endDateData).setHours(0, 0, 0, 0));
	const diffDays =
		Math.ceil(
			Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
		) + 1;

	for (let i = 0; i < diffDays; i++) {
		const currentDate = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
		const currentDateISO = currentDate.toISOString().split("T")[0];
		const dayOfWeek = currentDate.getDay();
		if (
			!offday.includes(dayOfWeek) &&
			!holidays.some((holiday) => holiday === currentDateISO)
		) {
			// Exclude Sunday (0), Saturday (6) and holidays
			workingDays++;
		}
	}
	if (half_days && half_days.length >= 1) {
		workingDays -= 0.5 * half_days.length;
	}

	return workingDays;
};

// Function to capitalize the first letter of leaves
export const capitalizeFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRange = (...numbers: number[]) => {
	return [Math.min(...numbers), Math.max(...numbers)];
};
