/**
 * Terra's Login Page with Microsoft sign in
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CoverFullLogo from "../assets/image/katimas_cover.jpg";
import katimasLogo from "../assets/image/KreditAsia_Mainlogo_Main logo_blue bg.png";
import Cookies from "js-cookie";
//import poweredByLogo from "../assets/image/dragonfly.svg";
import "../assets/css/loginPage.css";
import {
  Layout,
  Button,
  Input,
  ConfigProvider,
  theme,
  Space,
  Form,
  Image,
  Divider,
  Alert,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import microsoftLogo from "../assets/image/Microsoft_logo.svg";
// import IluvatarLogo from "../assets/image/Iluvatar_loginlogo.png";
import { GetAntIcon, GetFileType } from "../utils/ant_icons";
// import { loginUserAcc } from "../services/api-server/user_acc";
import Emitter from "../utils/emitter";
import { getAlertStyle } from "../utils/utils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { isMobile } from "react-device-detect";
import { loginAcc } from "../services/api-server/login";

const { Content } = Layout;

const poweredByLogo: string = "/api/__core/get_rails_logo";

const LoginPage = (props: any) => {
  const navigate = useNavigate();
  const [alertmsg, setAlertmsg] = useState<any>(null);
  const userRole = props?.userRole;
  // When form is finished (Temp unavailable)

  //   useEffect(() => {
  //     if (
  //       localStorage.getItem(`idToken`) &&
  //       localStorage.getItem(`idToken`) !== undefined
  //     ) {
  //       navigate(`/leave`, { replace: true });
  //     }
  //   });

  //   useEffect(() => {
  //     if (localStorage.getItem(`LoginMessage`)) {
  //       setAlertmsg({
  //         type: "warning",
  //         message: localStorage.getItem(`LoginMessage`),
  //         top: true,
  //         closeable: false,
  //       });
  //       setInterval(() => {
  //         localStorage.removeItem(`LoginMessage`);
  //         setAlertmsg(null);
  //       }, 3000);
  //     }
  //   }, []);

  const onFinish = (values: any) => {
    loginAcc(values)
      .then((_data: any) => {
        if (_data?.data === "Login successful") {
          setAlertmsg({
            type: "success",
            message: "Login successful",
            top: true,
            closeable: true,
          });
          setTimeout(() => {
            // First, navigate to a temporary route
            navigate(`/loginsuccess`, { replace: true });
          }, 2000);
        } else {
          setAlertmsg({
            type: "warning",
            message: "Login failed. Account does not exist, please register",
            top: true,
            closeable: true,
          });
        }
      })
      .catch((error) => {
        // console.error("Login failed", error.response.data.detail);
        setAlertmsg({
          type: "warning",
          message: "Login failed. Incorrect password, please try again",
          top: true,
          closeable: true,
        });
      });
  };

  // const onFinish = (values: any) => {
  //   if (values.username !== "admin") {
  //     setAlertmsg({
  //       type: "warning",
  //       message: "Login failed. Account does not exist, please register",
  //       top: true,
  //       closeable: true,
  //     });
  //   } else if (values.password !== "admin") {
  //     setAlertmsg({
  //       type: "warning",
  //       message: "Login failed. Incorrect password, please try again",
  //       top: true,
  //       closeable: true,
  //     });
  //   } else {
  //     setAlertmsg({
  //       type: "success",
  //       message: "Login successful",
  //       top: true,
  //       closeable: true,
  //     });

  //     setTimeout(() => {
  //       // window.location.href = "/loginsuccess";
  //       navigate(`/report_generation`, { replace: true });
  //     }, 2000); // 2000 milliseconds = 2 seconds
  //   }
  // };

  // loginUserAcc(values)
  //     .then((res: any) => {
  //      //console.log("Success:", res);
  //         window.location.href = "/loginsuccess";
  //         // navigate(`/dashboard`, { replace: true });
  //         // window.location.href = res;
  //     })
  //     .catch((err: any) => {
  //      //console.log("Failed:", err);
  //         setAlertmsg({
  //             type: "warning",
  //             message:
  //                 "Username or password incorrect. Please try again.",
  //             top: true,
  //             closeable: true,
  //         });

  //         // alert("Username or password is incorrect. Please try again.")
  //         // Emitter.emit("alert", {
  //         //     type: "error",
  //         //     title: "Error",
  //         //     message:
  //         //         "Username or password is incorrect. Please try again.",
  //         //     description: "",
  //         //     top: true,
  //         //     closeable: false,
  //         //     timeout: 3000,
  //         // });
  //     });
  // };

  // Login Via Microsoft EntraID
  const MicrosoftSignIn = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/login`;
    // axios
    //     .get(`${process.env.REACT_APP_SERVER_URL}/login`)
    //     .catch((err: any) => {
    //         Emitter.emit("alert", {
    //             type: "error",
    //             title: "Error",
    //             message: err,
    //             description: "",
    //             top: true,
    //             closeable: false,
    //             timeout: 3000,
    //         });
    //     });
  };

  return (
    <Layout className="login-body">
      <Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          className="container"
        >
          {!isMobile && (
            <div className="left-container">
              <div className="container-center">
                <div className="display-container"></div>
              </div>
            </div>
          )}

          <div
            className="right-container"
            style={{ minWidth: isMobile ? "100%" : "450px" }}
          >
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "40px",
              }}
              className="login-header-container"
              direction="vertical"
            >
              <Image
                src={katimasLogo}
                preview={false}
                width={370}
                // height={50}
              ></Image>
            </Space>
            <Space className="login-container" direction="vertical" size={50}>
              <Form
                name="login_form"
                className="login-form"
                onFinish={onFinish}
              >
                <div style={{ marginBottom: "15px" }}>
                  <Form.Item name="username">
                    <Input
                      // className="login-input"
                      required
                      prefix={
                        <span className="login-icon">
                          {GetAntIcon("email")}
                        </span>
                      }
                      placeholder="Username"
                      style={{
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="password">
                    <Input.Password
                      // className="login-input"
                      required
                      prefix={
                        <span className="login-icon">{GetAntIcon("lock")}</span>
                      }
                      type="password"
                      placeholder="Password"
                      style={{
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Form.Item>
                </div>

                <Form.Item style={{ marginBottom: "0px" }}>
                  {" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    // onClick={MicrosoftSignIn}
                    className="login-form-button normal-login-button"
                  >
                    Sign in
                  </Button>
                </Form.Item>
                <Form.Item
                  style={{
                    marginBottom: "10px",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <a
                    style={{
                      color: "#FFFFFFD9",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    Forgot password
                  </a>{" "}
                  &nbsp;<span style={{ color: "#FFFFFF30" }}>|</span>&nbsp;
                  <a
                    style={{
                      color: "#7F61FF",

                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    Register new account
                  </a>
                </Form.Item>
                <span
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    color: "#FFFFFF",
                  }}
                >
                  Having problems?{" "}
                  <a
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "600",
                    }}
                  >
                    Contact us
                  </a>
                </span>
              </Form>
            </Space>
            <div className="login-container-footer">
              <span
                style={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                Empowering Communities, Transforming Lives.
              </span>
              {/* <Image
                                preview={false}
                                height="60px"
                                width="60px"
                                src={poweredByLogo}
                            /> */}
            </div>
          </div>
          {alertmsg && (
            <Alert
              className={
                alertmsg?.top ? "alert-message-box-top" : "alert-message-box"
              }
              type={alertmsg?.type}
              message={alertmsg?.message}
              description={alertmsg?.description}
              showIcon
              closable={alertmsg?.closeable}
              afterClose={() => setAlertmsg(null)}
              style={{
                fontFamily: "Motiva Sans",
                ...getAlertStyle(alertmsg?.type),
              }}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default LoginPage;
