/**
 * default exports for other modules to use
 */

import { AnyARecord } from "dns";
import Emitter from "./emitter";
import Cookies from "js-cookie";

export const url = process.env.REACT_APP_SERVER_URL as string;

///// k-server routes to VM
export const serverConnection = url;

export const superAdminRole: any = process.env.REACT_APP_SUPER_ADMIN_TAG;
// export let accessToken: any = localStorage.getItem(`accessToken`);
// Set a cookie
// Cookies.set("accessToken", "your_token_value", { expires: 7 }); // Expires in 7 days

// // Get a cookie
// const newtoken = Cookies.get("accessToken");
// console.log(newtoken); // Outputs the value of the cookie

// Remove a cookie
// Cookies.remove("accessToken_en");

// Retrieve and parse the cookie
let companyAccessCookie: any = Cookies.get("permissions");

// Function to get a specific cookie by name
function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift() || null;
  }
  return null;
}

let companyAccesses: any = Cookies.get("companyAccesses");
let branchAccesses: any = Cookies.get("branchAccesses");
let permissions: any = Cookies.get("permissions");

const processCookies = (cookieValue: any) => {
  if (cookieValue) {
    // Step 1: Replace \054 with a comma
    cookieValue = cookieValue.replace(/\\054/g, ",");

    // Step 2: Split the string into an array
    let resultArray = cookieValue.split(",");

    // Optional: Trim whitespace from each element
    return resultArray.map((item: any) => item.trim());
  } else {
    return [];
  }
};

export let company_access = processCookies(companyAccesses);
export let branch_access = processCookies(branchAccesses);
export let permissions_access = processCookies(permissions);

// console.log("company_access", company_access);
// console.log("branch_access", branch_access);

export const idToken: any = localStorage.getItem(`idToken`);
export let pbiToken: any = localStorage.getItem(`pbiToken`);

let accessToken = Cookies.get("accessToken_en");

export let AuthHeader: any = {
  headers: {
    API_KEY: "1234",
    Authorization: `Bearer ${accessToken}`,
  },
};

export let LoginAuthHeader: any = {
  headers: {
    API_KEY: "1234",
  },
};

// export let AuthHeader: any = {
//   headers: {
//     API_KEY: "1234",
//   },
// };

// export let AuthHeader: any = {
//     headers: {
//       Authorization: accessToken,
//     },
//     withCredentials: true,
//   };

// Emitter.on("refreshed", () => {
//   accessToken = localStorage.getItem(`accessToken`);
//   pbiToken = localStorage.getItem(`pbiToken`);
//   AuthHeader = {
//     headers: {
//       Authorization: accessToken,
//     },
//     withCredentials: true,
//   };
//   // AuthHeader = AuthHeader;
// });
