import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Layout, Image, Button, Drawer } from "antd";
import {
  MenuOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraAddOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
// import menuPng from "../assets/image/katimas_white.png";
import menuPng from "../assets/image/menusmall.png";
import menuBIGPng from "../assets/image/menubig.png";
import menuBackgroundPng from "../assets/image/KAI_sidebar.png";
import { GetAntIcon } from "../utils/ant_icons";
import { logout } from "../utils/utils";
import { superAdminRole } from "../utils/_exports";
import { useNavigate } from "react-router";
import Emitter from "../utils/emitter";
import { MenuItem } from "@mui/material";
import { HomeContext } from "../containers/Home";
import { isMobile } from "react-device-detect";

const { Sider } = Layout;

// Change container key to find the proper component. You will need to go to utils/containers.tsx to add new components into it
export const initialMainMenu: any = [
  {
    key: "dashboard-menu",
    label: <span className="extra-menu-item">Dashboard</span>,
    propTitle: "Dashboard",
    container: "dashboardContainer",
    icon: GetAntIcon("app"),
    to: "/dashboard",
    permissions: "dashboard view",
  },
  {
    key: "datahub-menu",
    label: <span className="extra-menu-item">Report generation</span>,
    propTitle: "Report generation",
    container: "tabContainer",
    icon: GetAntIcon("database"),
    to: "/report_generation",
    permissions: "report view",
  },
];

export const initialExtraMenu: any = [
  // {
  //   key: "admin-menu",
  //   label: <span className="extra-menu-item">Administration</span>,
  //   propTitle: "Administration",
  //   container: "administrationContainer",
  //   // to: "/administration",
  //   icon: GetAntIcon("setting"),
  // },
  {
    key: "logout",
    label: <span className="extra-menu-item">Sign out</span>,
    propTitle: "Sign out",
    icon: GetAntIcon("logout"),
  },
  // {
  //   key: "profile-menu",
  //   label: <span className="extra-menu-item">Profile</span>,
  //   propTitle: "Profile",
  //   icon: GetAntIcon("user"),
  //   children: [
  //     {
  //       key: "manage-account",
  //       label: <span className="extra-menu-item">Manage account</span>,
  //       propTitle: "Manage account",
  //     },
  //     { key: "some-divider", type: "divider" },
  //     {
  //       key: "logout",
  //       label: <span className="extra-menu-item">Sign out</span>,
  //       propTitle: "Sign out",
  //     },
  //   ],
  // },
];

const MainMenu = (props: any) => {
  const context: any = useContext(HomeContext);
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<any>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const renderMenu = () => {
    const createMainMenu = (roles: any) => {
      return initialMainMenu.map((menuItem: any) => {
        if (menuItem.requires_admin == true) {
          if (roles?.includes(superAdminRole)) {
            return menuItem;
          } else {
            return null;
          }
        } else {
          return menuItem;
        }
      });
    };

    const handleMainMenu = async (info: any) => {
      try {
        await context.handlePageChange();
        if (info.item.props.to) {
          setSelectedKeys([info.key]);
          Emitter.emit("menuChange", [info.item.props]);
          navigate(info.item.props.to);
        }
      } catch {}
    };
    return (
      <div style={{ height: "50%", overflowY: "auto" }}>
        <Menu
          className="main-menu"
          mode="inline"
          defaultSelectedKeys={["datahub-menu"]}
          selectedKeys={props.selectedKey || selectedKeys}
          items={createMainMenu(props.roles)}
          onClick={(info: any) => {
            setDrawerVisible(false);
            handleMainMenu(info);
          }}
        ></Menu>
      </div>
    );
  };

  const renderExtraMenu = () => {
    const createExtraMenu = (roles: any) => {
      return initialExtraMenu.map((menuItem: any) => {
        if (menuItem.requires_admin == true) {
          if (roles?.includes(superAdminRole)) {
            return menuItem;
          } else {
            return null;
          }
        } else {
          return menuItem;
        }
      });
    };
    const handleExtraMenu = async (info: any) => {
      try {
        await context.handlePageChange();
        if (info.item.props.to) {
          Emitter.emit("menuChange", [info.item.props]);
          setSelectedKeys([info.key]);
          if (info.item.props.requires_admin) {
            // navigate("/admin" + info.item.props.to);
            navigate(info.item.props.to);
          } else {
            navigate(info.item.props.to);
          }
        }
        if (info.key === "logout") {
          logout();
        }
        if (info.key === "manage-account") {
          window.open(
            "https://myaccount.microsoft.com/?ref=MeControl",
            "_blank"
          );
        }
      } catch {}
    };

    return (
      <Menu
        selectedKeys={props.selectedKey || selectedKeys}
        className="extra-menu"
        style={{ marginTop: "auto" }}
        mode="inline"
        items={createExtraMenu(props.roles)}
        onClick={(info: any) => {
          setDrawerVisible(false);
          handleExtraMenu(info);
        }}
      ></Menu>
    );
  };

  const handleShowDrawer = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <>
      {props.option != "Mega" ? (
        isMobile ? (
          <>
            <Button
              type="primary"
              onClick={handleShowDrawer}
              style={{
                position: "fixed",
                top: "10px",
                left: "10px",
                zIndex: 100,
              }}
            >
              <MenuOutlined />
            </Button>
            <Drawer
              placement="left"
              closable={false}
              onClose={handleCloseDrawer}
              open={drawerVisible}
              width={300}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  marginBottom: "15px",
                }}
              >
                <Image
                  preview={false}
                  width={"75px"}
                  className="home-logo"
                  src={menuPng}
                  alt="Logo"
                />
              </div>
              {renderMenu()}
              {renderExtraMenu()}
            </Drawer>
          </>
        ) : (
          <Sider
            trigger={
              props.collapsed ? (
                <DoubleRightOutlined style={{ color: "white" }} />
              ) : (
                <DoubleLeftOutlined style={{ color: "white" }} />
              )
            }
            className="main-sider"
            collapsed={props.collapsed}
            onCollapse={props.onCollapse}
            collapsible
            width={props.collapsed ? "80px" : "230px"}
            style={{
              boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)",
              backgroundImage: `url("${menuBackgroundPng}")`,
              backgroundSize: "cover", // Optional: Ensures the image covers the area
              backgroundPosition: "center", // Optional: Center the background image
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "15px",
              }}
            >
              {props.collapsed ? (
                <Image
                  preview={false}
                  width={"35px"}
                  className="home-logo"
                  src={menuPng}
                  alt="Logo"
                />
              ) : (
                <Image
                  preview={false}
                  width={"150px"}
                  className="home-logo"
                  src={menuBIGPng}
                  alt="Logo"
                />
              )}
            </div>
            {renderMenu()}
            {renderExtraMenu()}
          </Sider>
        )
      ) : (
        <></>
      )}
    </>
  );
};

MainMenu.propTypes = {
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  option: PropTypes.any,
  roles: PropTypes.array,
  selectedKey: PropTypes.any,
};

export default MainMenu;
