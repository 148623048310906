/**
 * File to set containers and props on load
 */

import LookupManager from "../containers/LookupManager";
import GenericTabContainer from "../containers/GenericTabContainer";
import Default from "../containers/Default";
// import Profile from "../containers/Profile";
import ExplorerContainer from "../containers/ExplorerContainer";
// import UserRoleManagerContainer from "../containers/UserRoleManagerContainer";

export const containerList = [];

export const adminContainerList = [];

// Always add params, it is important for passing user & menu data
export const setContainer = (
  component: any,
  title: any,
  key: any,
  params: any,
  role: any,
  user: any
) => {
  switch (component) {
    case "default":
      return <Default params={params} />;
    case "dashboardContainer":
      return <ExplorerContainer params={params} />;
    case "tabContainer":
      return <GenericTabContainer params={params} />;
    case "explorerContainer":
      return <ExplorerContainer params={params} />;
    case "lookupManager":
      return <LookupManager params={params} />;
  }
};

export const setAdminContainer = (
  component: any,
  params: any | null = null
) => {
  switch (component) {
  }
};
