import Plot from "react-plotly.js";

const PieChart = (props: any) => {
  const getPercentageChange = () => {
    if (props.totalloanpercentage > 0) {
      return `<span style="color: rgba(40, 167, 69, 0.85);">+${props.totalloanpercentage.toFixed(
        2
      )}%</span>`;
    } else if (props.totalloanpercentage < 0) {
      return `<span style="color: rgb(229, 56, 61, 0.85);">${props.totalloanpercentage.toFixed(
        2
      )}%</span>`;
    } else {
      return `<span style="color: rgba(128, 128, 128, 0.85);">${props.totalloanpercentage}%</span>`;
    }
  };

  const values = [];
  const labels = [];
  const colors = [];

  if (props.totalloanLT !== 0) {
    values.push(props.totalloanLT);
    labels.push("Loan Term (LT)");
    colors.push("#3e879b"); // Set color for Loan Term (LT)
  }
  if (props.totalloanPFI !== 0) {
    values.push(props.totalloanPFI);
    labels.push("Personal Financing-i (PFI)");
    colors.push("#c454b0"); // Set color for Personal Financing-i (PFI)
  }
  if (props.totalloanCLI !== 0) {
    values.push(props.totalloanCLI);
    labels.push("Cash Line-i (CLI)");
    colors.push("#9573fb"); // Set color for Cash Line-i (CLI)
  }

  return (
    <Plot
      data={[
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          texttemplate: "RM%{value}", // Format for values in legend
          automargin: false,
          textposition: "outside",
          marker: {
            colors: colors, // Apply colors to segments
          },
          title: {
            text: `Total loans <br> <b>RM${props.totalloan.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}</b><br>${getPercentageChange()}`,
            // font: {
            //   size: 12,
            // },
            font: {
              family: "PP Neue Machina",
            },
            position: "middle center",
          },
        },
      ]}
      layout={{
        title: {
          text: props.title,
          font: {
            family: "PP Neue Machina",
            size: 16,
          },
        },
        height: 350,
        legend: {
          orientation: "h",
          y: -1,
          yanchor: "bottom",
          x: 0,
          itemclick: false,
          itemdoubleclick: false,
        },
        font: {
          family: "Motiva Sans",
        },
      }}
      config={{
        displaylogo: false, // Remove the Plotly logo
      }}
      style={{ width: "100%" }}
    />
  );
};

export default PieChart;
