import React, { useState } from "react";
import Plot from "react-plotly.js";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const BarStack = (props: any) => {
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  const handleYearChange = (date: any) => {
    const year = date.year();
    setSelectedYear(year);
    // Pass the selected year back to the parent component
    props.onYearChange(year);
  };

  const disabledDate = (current: any) => {
    // Disable dates that are in the future
    return current && current > dayjs().endOf("day");
  };

  const plotData = (props.barstackData || []).map((account: any) => {
    let color = "";
    if (account.sub_type_acct === "LT") {
      color = "#3e879b";
    } else if (account.sub_type_acct === "PF-i") {
      color = "#c454b0";
    } else if (account.sub_type_acct === "CL-i") {
      color = "#9573fb";
    }

    return {
      name: account.sub_type_acct,
      y: account.total.map((value: any, index: any) => (value > 0 ? value : 0)), // Adjust y values as needed
      x: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      type: "bar",
      marker: { color: color },
      hovertemplate: " RM %{y}", // Add RM to the hover text
    };
  });

  return (
    <>
      {props.barstackData ? (
        <div>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            {/* <p style={{ margin: 0 }}>{props.title}</p> */}
            {props.selectedItem == "Sales by product" && (
              <DatePicker
                disabledDate={disabledDate}
                picker="year"
                defaultValue={dayjs(`${selectedYear}-01-01`)}
                onChange={handleYearChange}
                style={{ marginLeft: 16 }}
              />
            )}
          </div>
          <Plot
            data={plotData}
            layout={{
              title: {
                text: props.title,
                font: {
                  family: "PP Neue Machina",
                  size: 16,
                },
              },
              height: 350,
              barmode: "stack",
              showlegend: true,
              legend: {
                // x: 100,
                itemclick: false,
                itemdoubleclick: false,
              },
              yaxis: {
                tickformat: ",.0f", // This ensures the y-axis values are displayed as normal numbers
              },
              font: {
                family: "Motiva Sans",
              },
            }}
            config={{
              displaylogo: false, // Remove the Plotly logo
            }}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ) : null}
    </>
  );
};

export default BarStack;
