import React from "react";
import Plot from "react-plotly.js";

const PieLabelChart = (props: any) => {
  const getPercentageChange = () => {
    if (props.totalloanpercentage > 0) {
      return `<span style="color: rgba(40, 167, 69, 0.85);">+${props.totalloanpercentage.toFixed(
        2
      )}%</span>`;
    } else if (props.totalloanpercentage < 0) {
      return `<span style="color: rgb(229, 56, 61, 0.85);">${props.totalloanpercentage.toFixed(
        2
      )}%</span>`;
    } else {
      return `<span style="color: rgba(128, 128, 128, 0.85);">${props.totalloanpercentage}%</span>`;
    }
  };

  const values = [];
  const labels = [];
  const colors = [];

  if (props.totalloannewcustomer !== 0) {
    values.push(props.totalloannewcustomer);
    labels.push("New Customer");
    colors.push("#ffbd8d"); // Set color for Loan Term (LT)
  }
  if (props.totalloanreturningcustomer !== 0) {
    values.push(props.totalloanreturningcustomer);
    labels.push("Returning Customer");
    colors.push("#ff9999"); // Set color for Personal Financing-i (PFI)
  }
  if (props.totalloanexistingcustomer !== 0) {
    values.push(props.totalloanexistingcustomer);
    labels.push("Existing Customer");
    colors.push("#52bfbc"); // Set color for Cash Line-i (CLI)
  }

  return (
    <Plot
      data={[
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          //   name: "Prime numbers",
          title: {
            text: `Total customer <br> <b>${Math.round(
              props.totalloancustomer
            ).toLocaleString()}</b><br>${getPercentageChange()}`,
            // font: {
            //   size: 12,
            // },
            font: {
              family: "PP Neue Machina",
              size: 16,
            },
            position: "middle center",
          },
          textinfo: "label+percent",
          marker: {
            colors: colors, // Apply colors to segments
          },
          automargin: true,
          rotation: 270,
        },
      ]}
      layout={{
        height: 350,
        title: {
          text: props.title,
        },
        legend: {
          orientation: "h",
          y: -1,
          yanchor: "bottom",
          x: 0.1,
          itemclick: false,
          itemdoubleclick: false,
          // xanchor: "center",
        },
        font: {
          family: "Motiva Sans",
        },
        // margin: { t: 150, b: 100, l: 10, r: 0 },
      }}
      config={{
        displaylogo: false, // Remove the Plotly logo
      }}
      style={{ width: "100%" }}
    />
  );
};

export default PieLabelChart;
