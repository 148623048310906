import React, { useEffect } from "react";
import Cookies from "js-cookie";

const LoginSuccess = (props: any) => {
  const redirect = () => {
    // console.log("cooke", Cookies.get("accessToken_en"));
    if (Cookies.get("accessToken_en")) {
      window.location.href = `/dashboard`;
      // navigate(`/report_generation`, { replace: true });
    } else {
      window.location.href = `/`;
      // navigate(`/`, { replace: true });
    }
  };

  useEffect(() => {
    // loginRedirect();
    // console.log(":");
    redirect();
  }, []);

  return <></>;
};

export default LoginSuccess;
