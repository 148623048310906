import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Input,
  Select,
  Tag,
  Divider,
  ColorPicker,
  DatePicker,
  DatePickerProps,
  InputNumber,
  Tree,
  TreeSelect,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { getFormOptionList } from "../utils/lookup_list";
import { GetAntIcon } from "../utils/ant_icons";
import moment from "moment";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
const { SHOW_PARENT } = TreeSelect;

const CustomForm = (props: any) => {
  const [initialForm, setInitialForm]: any = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectFieldValues, setSelectFieldValues] = useState<any>({});
  const [employeeData, setEmployeeData] = useState<any>([]);

  const [filteredExtraData, setFilteredExtraData] = useState<any>(null);

  // Hooks for End Date
  // const [endDateVisibility, setEndDateVisibility] = useState(false);
  const endDateVisibility = props.endDateVisibility;
  const setEndDateVisibility = props.setEndDateVisibility;

  const formRef = props.formRef;
  const dataTable = props.dataTable; // Data from the table which is previously named as extraData
  const activeRowKey = props?.activeRowKey;
  const handleFormCancel = props?.handleFormCancel;
  const handleFormSave = props?.handleFormSave;
  const handleFormDelete = props?.handleFormDelete;
  const getDeleteMessage = props?.getDeleteMessage;
  const resetForm = props?.resetForm;
  const setFormReset = props?.setFormReset;
  const tabKey = props?.tabKey;
  const positionData = props?.positionData;
  const employeeType = props.employeeType;
  const reportcompanyData = props.reportcompanyData;
  const reportproductData = props.reportproductData;

  const [checkedValue, setCheckedValue]: any = useState([]);

  const [reportDate, setReportDate]: any = useState(null);
  const [companyoptions, setCompanyOptions]: any = useState([]);
  const [productoptions, setProductOptions]: any = useState([]);
  const [reportdatedata, setReportDateData]: any = useState([]);

  // Watch all values
  const formValues: any = Form.useWatch([], formRef);

  useEffect(() => {
    if (
      formRef.getFieldsValue().report_date &&
      formRef.getFieldsValue().report_date != null &&
      formRef.getFieldsValue().report_date != ""
    ) {
      const reportData = reportproductData[reportDate];
      const companyupdatedOptions = handleTemporaryOptions(
        "related_company",
        "report_date"
      );
      setCompanyOptions(companyupdatedOptions);
      formRef.setFieldsValue({
        company_name: undefined,
      });
      const productupdatedOptions = handleTemporaryOptions(
        "report_product",
        "report_date"
      );
      setProductOptions(productupdatedOptions);
      formRef.setFieldsValue({
        sub_acct_type: undefined,
      });
      setReportDateData(reportData);
    } else {
      setCompanyOptions([]);
      setProductOptions([]);
      setReportDateData([]);
    }
  }, [formRef.getFieldsValue().report_date]);

  useEffect(() => {
    if (
      (formValues?.company_name?.length != 0 &&
        formValues?.sub_acct_type === undefined) ||
      (formValues?.company_name?.length != 0 &&
        formValues?.sub_acct_type?.length === 0)
    ) {
      const productupdatedOptions = handleTemporaryOptions(
        "report_product",
        "report_date",
        formValues?.company_name
      );
      setProductOptions(productupdatedOptions);
    } else if (
      formValues?.company_name?.length != 0 &&
      formValues?.sub_acct_type?.length != 0 &&
      formValues?.company_name === undefined
    ) {
      formRef.setFieldsValue({ company_name: [] });
      formRef.setFieldsValue({ sub_acct_type: [] });
      setCompanyOptions([]);
      setProductOptions([]);
    } else if (
      formValues?.company_name?.length != 0 &&
      formValues?.sub_acct_type?.length != 0 &&
      !formValues?.company_name.includes("all")
    ) {
      const productupdatedOptions = handleTemporaryOptions(
        "report_product",
        "report_date",
        formValues?.company_name
      );
      setProductOptions(productupdatedOptions);
      // Filter reportData using companyArr
      if (reportdatedata !== undefined || reportdatedata?.length > 0) {
        // console.log("reportdatedata", reportdatedata);
        const filteredReportDataByCompany = reportdatedata.filter(
          (data: any) =>
            formValues?.company_name.includes(data.companyid) ||
            formValues?.company_name.includes(data.branchid)
        );
        // Filter the filtered reportData using productArr
        const finalFilteredReportData = filteredReportDataByCompany.filter(
          (data: any) => formValues?.sub_acct_type.includes(data.sub_acct_type)
        );
        // formRef.setFieldsValue({
        //   sub_acct_type: finalFilteredReportData,
        // });
      }
    } else if (formValues?.company_name?.length == 0) {
      const productupdatedOptions = handleTemporaryOptions(
        "report_product",
        "report_date"
      );
      setProductOptions(productupdatedOptions);
    } else if (formValues?.company_name.includes("all")) {
      const productupdatedOptions = handleTemporaryOptions(
        "report_product",
        "report_date"
      );
      setProductOptions(productupdatedOptions);
    }
  }, [formValues?.company_name]);

  useEffect(() => {
    if (
      (formValues?.sub_acct_type?.length != 0 &&
        formValues?.company_name === undefined) ||
      (formValues?.sub_acct_type?.length != 0 &&
        formValues?.company_name?.length === 0)
    ) {
      if (formValues?.sub_acct_type?.includes("all")) {
        const companyupdatedOptions = handleTemporaryOptions(
          "related_company",
          "report_date"
        );
        setCompanyOptions(companyupdatedOptions);
      } else {
        const companyupdatedOptions = handleTemporaryOptions(
          "related_company",
          "report_date",
          formValues?.sub_acct_type
        );
        setCompanyOptions(companyupdatedOptions);
      }
    } else if (
      formValues?.sub_acct_type?.length != 0 &&
      formValues?.company_name?.length != 0 &&
      formValues?.sub_acct_type === undefined
    ) {
      formRef.setFieldsValue({ company_name: [] });
      formRef.setFieldsValue({ sub_acct_type: [] });
      setCompanyOptions([]);
      setProductOptions([]);
    } else if (
      formValues?.sub_acct_type?.length != 0 &&
      formValues?.company_name?.length != 0 &&
      !formValues?.sub_acct_type.includes("all")
    ) {
      const companyupdatedOptions = handleTemporaryOptions(
        "related_company",
        "report_date",
        formValues?.sub_acct_type
      );
      setCompanyOptions(companyupdatedOptions);
      if (reportdatedata !== undefined || reportdatedata?.length > 0) {
        const filteredReportData = reportdatedata.filter((data: any) =>
          formValues?.sub_acct_type.includes(data.sub_acct_type)
        );
        if (formValues?.company_name.includes("all")) {
          const filteredCompanyArr = formValues?.company_name.filter(
            (company: any) =>
              filteredReportData.some(
                (data: any) =>
                  data.companyid === company || data.branchid === company
              )
          );
          formRef.setFieldsValue({
            company_name: ["all"],
          });
        } else {
          const filteredCompanyArr = formValues?.company_name.filter(
            (company: any) =>
              filteredReportData.some(
                (data: any) =>
                  data.companyid === company || data.branchid === company
              )
          );
          formRef.setFieldsValue({
            company_name: filteredCompanyArr,
          });
        }
      }
    } else if (formValues?.sub_acct_type?.length == 0) {
      const companyupdatedOptions = handleTemporaryOptions(
        "related_company",
        "report_date"
      );
      setCompanyOptions(companyupdatedOptions);
    } else if (formValues?.sub_acct_type.includes("all")) {
      const companyupdatedOptions = handleTemporaryOptions(
        "related_company",
        "report_date"
      );
      setCompanyOptions(companyupdatedOptions);
    }
  }, [formValues?.sub_acct_type]);

  const filterOption = (input: any, option: any) => {
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  const onChange = (value: any, label: any, extra: any) => {
    let new_value = [];
    if (value.includes("all")) {
      new_value = productoptions?.[0].children.map((item: any) => item.key);
    } else {
      new_value = value;
    }
    formRef.setFieldsValue({
      product_type: new_value,
    });
    setCheckedValue(new_value);
  };

  const handleFormChange = () => {
    setFormReset(false);
    const values = formRef.getFieldsValue();
    // if (formRef.getFieldsValue("sub_acct_type")) {
    //   console.log("productupdatedOptions", productoptions);
    // }
  };

  const handleSelectOptions = (
    keyProperty: any,
    keyLabel: any,
    data: any = null,
    relay: any = null
  ) => {
    let mappedArray: any = [];
    const uniqueValues = new Set();

    // set Dropdown selection list
    if (keyProperty && keyLabel) {
      let dataArray = data || dataTable;
      // If it requires a relay but the relay value has no data, return empty
      if (
        (relay && formRef.getFieldValue(relay) == undefined) ||
        formRef.getFieldValue(relay) == ""
      ) {
        mappedArray = [];
      } else {
        mappedArray = dataArray
          .map((obj: any) => {
            const label = obj[keyLabel];
            const key = obj[keyProperty];

            // Check if the value is unique
            if (!uniqueValues.has(key) && label != "" && key) {
              uniqueValues.add(key);
              return { label: label, value: key };
            }

            return null; // If not unique, return null
          })
          .filter(Boolean) // Remove null values
          .sort((a: any, b: any) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            return 0;
          });
      }
    }

    return mappedArray;
  };

  const handleTemporaryOptions = (
    caseName: any,
    relay: any = null,
    filterItem: any = null
  ) => {
    let mappedArray: any = [];
    const uniqueValues = new Set();
    switch (caseName) {
      case "report_type":
        mappedArray = [
          {
            key: "collection",
            label: "Collection report",
            value: "collection",
          },
          // { key: "sales", label: "Sales report", value: "sales" },
          // { key: "operation", label: "Operations report", value: "operation" },
        ];
        break;
      case "report_product":
        const relay_Value = formRef.getFieldValue(relay);
        if (
          relay_Value == undefined ||
          relay_Value == "" ||
          (typeof relay_Value === "object" &&
            Object.keys(relay_Value).length === 0)
        ) {
          mappedArray = [];
          formRef.setFieldsValue({
            sub_acct_type: undefined,
          });
        } else {
          const reportData = reportproductData[relay_Value];
          if (reportData) {
            if (
              filterItem &&
              filterItem != undefined &&
              filterItem.length > 0
            ) {
              if (filterItem.includes("all")) {
                const uniqueFilteredData = reportData.filter(
                  (item: any, index: any, self: any) =>
                    index ===
                    self.findIndex(
                      (t: any) => t.sub_acct_type === item.sub_acct_type
                    )
                );
                // console.log("uniqueFilteredData", uniqueFilteredData);
                // console.log("uniqueFilteredData", uniqueFilteredData);
                // mappedArray = uniqueFilteredData.map((item: any) => ({
                //   key: item.sub_acct_type,
                //   label: item.sub_acct_type,
                //   value: item.sub_acct_type,
                // }));
                const newFilterData = uniqueFilteredData.map((item: any) => ({
                  key: item.sub_acct_type,
                  label: item.sub_acct_type,
                  value: item.sub_acct_type,
                }));
                const allProduct = {
                  title: "All Product",
                  key: "all",
                  value: "all",
                  children: newFilterData,
                };
                // console.log("allProduct", allProduct);

                mappedArray = [allProduct];
              } else {
                const finalFilteredData = reportData.filter(
                  (item: any) =>
                    filterItem.includes(item.branchid) ||
                    filterItem.includes(item.companyid)
                );
                const uniqueFilteredData = finalFilteredData.filter(
                  (item: any, index: any, self: any) =>
                    index ===
                    self.findIndex(
                      (t: any) => t.sub_acct_type === item.sub_acct_type
                    )
                );
                // mappedArray = uniqueFilteredData.map((item: any) => ({
                //   key: item.sub_acct_type,
                //   label: item.sub_acct_type,
                //   value: item.sub_acct_type,
                // }));
                const newFilterData = uniqueFilteredData.map((item: any) => ({
                  key: item.sub_acct_type,
                  label: item.sub_acct_type,
                  value: item.sub_acct_type,
                }));
                const allProduct = {
                  title: "All Product",
                  key: "all",
                  value: "all",
                  children: newFilterData,
                };
                // console.log("allProduct", allProduct);

                mappedArray = [allProduct];
              }
            } else {
              // Use a Set to track unique branch names
              const uniqueBranches = new Set();

              // Filter out duplicate branch names
              const filteredData = reportData.filter((item: any) => {
                if (uniqueBranches.has(item.sub_acct_type)) {
                  return false;
                }
                uniqueBranches.add(item.sub_acct_type);
                return true;
              });
              const newFilterData = filteredData.map((item: any) => ({
                key: item.sub_acct_type,
                label: item.sub_acct_type,
                value: item.sub_acct_type,
              }));
              const allProduct = {
                title: "All Product",
                key: "all",
                value: "all",
                children: newFilterData,
              };
              // console.log("allProduct", allProduct);
              mappedArray = [allProduct];
            }
          } else {
            mappedArray = [];
            formRef.setFieldsValue({
              sub_acct_type: undefined,
            });
          }
        }
        break;
      case "related_company":
        const relayValue = formRef.getFieldValue(relay);
        if (
          relayValue == undefined ||
          relayValue == "" ||
          (typeof relayValue === "object" &&
            Object.keys(relayValue).length === 0)
        ) {
          mappedArray = [];
          formRef.setFieldsValue({
            company_name: undefined,
          });
        } else {
          const reportData = reportcompanyData[relayValue];
          if (reportData) {
            if (
              filterItem &&
              filterItem != undefined &&
              filterItem.length > 0
            ) {
              const uniqueBranches = new Set();
              const finalFilteredData = reportData.filter((item: any) =>
                filterItem.includes(item.sub_acct_type)
              );

              // Filter out duplicate branch names
              const filteredData = finalFilteredData.filter((item: any) => {
                if (uniqueBranches.has(item.branch_name)) {
                  return false;
                }
                uniqueBranches.add(item.branch_name);
                return true;
              });

              const groupedData = filteredData.reduce((acc: any, item: any) => {
                const { company_name, branch_name, companyid, branchid } = item;
                if (!acc[company_name]) {
                  acc[company_name] = {
                    title: company_name,
                    key: companyid, // Ensure this key is unique
                    value: companyid, // Add value for the parent node
                    children: [],
                  };
                }
                acc[company_name].children.push({
                  title: branch_name,
                  key: branchid, // Ensure this key is unique
                  value: branchid, // Add value for the child node
                  label: branch_name,
                });
                return acc;
              }, {});
              // Convert the object to an array and sort by company_name
              const sortedGroupedData = Object.values(groupedData).sort(
                (a: any, b: any) => a.title.localeCompare(b.title)
              );

              // Sort the children by branch_name
              sortedGroupedData.forEach((company: any) => {
                company.children.sort((a: any, b: any) =>
                  a.title.localeCompare(b.title)
                );
              });
              // console.log("sortedGroupedData",sortedGroupedData)
              const allCompanies = {
                title: "All Companies",
                key: "all",
                value: "all",
                children: sortedGroupedData,
              };

              mappedArray = [allCompanies];
              // mappedArray = sortedGroupedData;
            } else {
              // Use a Set to track unique branch names
              const uniqueBranches = new Set();

              // Filter out duplicate branch names
              const filteredData = reportData.filter((item: any) => {
                if (uniqueBranches.has(item.branch_name)) {
                  return false;
                }
                uniqueBranches.add(item.branch_name);
                return true;
              });

              const groupedData = filteredData.reduce((acc: any, item: any) => {
                const { company_name, branch_name, companyid, branchid } = item;
                if (!acc[company_name]) {
                  acc[company_name] = {
                    title: company_name,
                    key: companyid, // Ensure this key is unique
                    value: companyid, // Add value for the parent node
                    children: [],
                  };
                }
                acc[company_name].children.push({
                  title: branch_name,
                  key: branchid, // Ensure this key is unique
                  value: branchid, // Add value for the child node
                  label: branch_name,
                });
                return acc;
              }, {});
              // Convert the object to an array and sort by company_name
              const sortedGroupedData = Object.values(groupedData).sort(
                (a: any, b: any) => a.title.localeCompare(b.title)
              );

              // Sort the children by branch_name
              sortedGroupedData.forEach((company: any) => {
                company.children.sort((a: any, b: any) =>
                  a.title.localeCompare(b.title)
                );
              });

              const allCompanies = {
                title: "All Companies",
                key: "all",
                value: "all",
                children: sortedGroupedData,
              };

              mappedArray = [allCompanies];
            }
          } else {
            mappedArray = [];
            formRef.setFieldsValue({
              company_name: undefined,
            });
          }
        }
        break;
      default:
        mappedArray = [];
        break;
    }
    return mappedArray;
  };

  // Set the form field type based on the type from the form list
  const setInputType = (element: any, index: any) => {
    let dataObject: any = dataTable;
    switch (element.type) {
      case "input":
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <Input
              autoFocus={index == 0 && !activeRowKey}
              autoComplete="off"
              onChange={(event: any) => {
                const value = event.target.value
                  .replace(/^ /, "")
                  .replace(/\s+/g, " ");
                formRef.setFieldValue(element.name, value);
                setFormReset(false);
              }}
              style={{
                width: element.width ? `${element.width * 4}%` : "100%",
                borderColor: element.borderColor,
              }}
            ></Input>
          </Form.Item>
        );
      case "claiminput":
        return (
          <Form.Item required={false} label={element.label} name={element.name}>
            <InputNumber
              style={{
                width: element.width ? `${element.width * 4}%` : "100%",
                borderColor: element.borderColor || "#000000",
                fontWeight: "bold",
              }}
            ></InputNumber>
          </Form.Item>
        );
      case "textArea":
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <TextArea
              autoFocus={index == 0 && !activeRowKey}
              onChange={() => {
                setFormReset(false);
              }}
            ></TextArea>
          </Form.Item>
        );
      case "select":
        dataObject = filteredExtraData || dataTable;
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <Select
              autoFocus={index == 0 && !activeRowKey}
              allowClear
              filterOption={filterOption}
              showSearch
              options={handleSelectOptions(
                element.optionKey,
                element.optionLabel,
                element.extraKey ? dataObject[element.extraKey] : null,
                element.relayFrom
              )}
              mode={element.mode || undefined}
              tagRender={(props: any) => {
                if (element.mode === "tags") {
                  let tag = dataTable?.find((element: any) => {
                    return props.label == element.name;
                  });
                  if (tag) {
                    return <Tag color={tag.color}>{tag.name}</Tag>;
                  }
                }
                return <></>;
              }}
              onChange={(value: any) => {
                setFormReset(false);
                //console.log("value", value);
              }}
            ></Select>
          </Form.Item>
        );
      case "colorPicker":
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
            initialValue={"#299BA3"}
          >
            <ColorPicker
              showText
              // defaultValue="#000000"
              onChange={() => {
                setFormReset(false);
              }}
            ></ColorPicker>
          </Form.Item>
        );
      case "grid":
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
              gap: `${element.gap}`,
            }}
          >
            {element.children.map((child: any, index: any) =>
              setInputType(child, index)
            )}
          </div>
        );
      case "gridx":
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: element.gridLength
                ? element.gridLength
                    .map((length: any) => `${length}fr`)
                    .join(" ")
                : `repeat(${element.children.length}, 1fr)`,
              gap: `${element.gap}`,
            }}
          >
            {element.children.map((child: any, index: any) =>
              setInputType(child, index)
            )}
          </div>
        );

      case "gridy":
        return (
          <div
            style={{
              display: "grid",
              gridTemplateRows: element.gridLength
                ? element.gridLength
                    .map((length: any) => `${length}fr`)
                    .join(" ")
                : `repeat(${element.children.length}, 1fr)`,
              gap: `${element.gap}`,
            }}
          >
            {element.children.map((child: any, index: any) =>
              setInputType(child, index)
            )}
          </div>
        );
      case "disabled":
        return (
          <Form.Item required={false} label={element.label} name={element.name}>
            <Input disabled></Input>
          </Form.Item>
        );
      case "datePicker":
        if (endDateVisibility || element.name === "date_join") {
          let fetchedDateString = formRef.getFieldValue(element.name);
          // console.log("test", element.name, fetchedDateString);
          // let formattedDate = fetchedDateString;
          let formattedDate = dayjs(fetchedDateString, "DD MMM YYYY HH:mm:ss");

          const customFormat: DatePickerProps["format"] = (value: any) =>
            `${value.format("DD MMM YYYY")}`;
          // if (!formattedDate.isValid()) {
          // 	formattedDate = fetchedDateString;
          // }
          return (
            <Form.Item
              label={element.label}
              name={element.name}
              rules={[{ required: element.required, message: "" }]}
            >
              <DatePicker
                style={{
                  width: element.width ? `${element.width * 4}%` : "100%",
                  borderColor: element.borderColor || "#000000",
                  backgroundColor: "rgba(255,255,255,0.1)",
                }}
                autoFocus={index == 0 && !activeRowKey}
                onChange={(date, x) => {
                  setFormReset(false);
                  // console.log(date);
                }}
                value={formattedDate || undefined}
                format={customFormat}
              ></DatePicker>
            </Form.Item>
          );
        } else {
          return null;
        }
      case "datePickerMonth":
        let fetchedDateString = formRef.getFieldValue(element.name);
        let formattedDate = dayjs(fetchedDateString, "MM YYYY");

        const customFormat: DatePickerProps["format"] = (value: any) =>
          `${value.format("MMM YYYY")}`;

        const maxMonth = dayjs(); // Set max month to today + 1 month

        const disabledDate = (current: any) => {
          return current && current > maxMonth.endOf("month");
        };
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              picker="month"
              style={{
                width: element.width ? `${element.width * 4}%` : "100%",
                backgroundColor: "rgba(255,255,255,0.1)",
              }}
              autoFocus={index == 0 && !activeRowKey}
              allowClear={false}
              onChange={(date, x) => {
                setFormReset(false);
                if (date) {
                  // Convert the date to a JavaScript Date object
                  let reportDate = new Date(
                    Date.UTC(date.year(), date.month(), 1)
                  );

                  // Update the form field with the new date
                  formRef.setFieldValue(
                    "report_date",
                    reportDate.toISOString().split("T")[0]
                  );
                  // handleTemporaryOptions("related_company", "report_date");
                  setReportDate(reportDate.toISOString().split("T")[0]);
                } else {
                  // Clear the form field when date is cleared
                  // console.log("tt");
                  formRef.setFieldValue("report_date", undefined);
                  // handleTemporaryOptions("related_company", "report_date");
                  setReportDate(null);
                }
              }}
              value={formattedDate || undefined}
              format={customFormat}
            ></DatePicker>
          </Form.Item>
        );
      case "divider":
        return (
          <Divider
            style={{
              margin: 0,
              marginTop: "20px",
              color: "rgba(255,255,255,0.45)",
            }}
            key={element.label}
            orientation="left"
          >
            {/* add color to label */}
            <span style={{ color: "rgba(0,0,0, 0.5)" }}>{element.label}</span>
          </Divider>
        );
      case "hidden":
        return (
          <Form.Item name={element.name} hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>
        );
      case "select-multiple":
        // let fieldValues = formRef.getFieldValue(element.name) || [];
        // return (
        //   <div
        //     style={{
        //       gap: "10px",
        //       display: "flex",
        //       flexDirection: "column",
        //     }}
        //   >
        //     <div
        //       style={{
        //         gap: "8px",
        //         display: "flex",
        //         flexDirection: "column",
        //       }}
        //     >
        //       <Form.Item
        //         style={{ display: "none" }}
        //         name={element.name}
        //         rules={[{ required: element.required, message: "" }]}
        //       ></Form.Item>
        //       <span>{element.label}</span>
        //       <Select
        //         autoFocus={index == 0 && !activeRowKey}
        //         filterOption={filterOption}
        //         showSearch
        //         options={handleSelectOptions(
        //           element.optionKey,
        //           element.optionLabel,
        //           element.extraKey ? dataObject[element.extraKey] : null,
        //           element.relayFrom
        //         )}
        //         value={null}
        //         autoClearSearchValue
        //         mode={element.mode || undefined}
        //         tagRender={(props: any) => {
        //           if (element.mode === "tags") {
        //             let tag = dataTable?.find((element: any) => {
        //               return props.label == element.name;
        //             });
        //             if (tag) {
        //               return <Tag color={tag.color}>{tag.name}</Tag>;
        //             }
        //           }
        //           return <></>;
        //         }}
        //         onChange={(value: any) => {
        //           let formValues = formRef.getFieldValue(element.name) || [];
        //           formValues = [...formValues];
        //           formValues.push(value);
        //           formRef.setFieldValue(element.name, formValues);

        //           let dataState: any = [];
        //           let data: any = {};

        //           if (filteredExtraData) {
        //             dataState = [...filteredExtraData[element.extraKey]];
        //             data = { ...filteredExtraData };
        //           } else {
        //             dataState = [...dataTable[element.extraKey]];
        //             data = { ...dataTable };
        //           }
        //           let filteredData = dataState.filter(
        //             (item: any) => item[element.optionKey] != value
        //           );
        //           data[element.extraKey] = [...filteredData];
        //           setFilteredExtraData(data);
        //         }}
        //       ></Select>
        //     </div>
        //     {dataTable[element.extraKey]
        //       ?.filter((item: any) => {
        //         return fieldValues?.includes(item[element.optionKey]);
        //       })
        //       .map((item: any) => {
        //         return (
        //           <div
        //             style={{
        //               display: "flex",
        //               alignItems: "center",
        //               gap: "10px",
        //               alignSelf: "stretch",
        //             }}
        //           >
        //             <Input
        //               style={{
        //                 color: "rgba(255,255,255,0.85)",
        //                 flex: 1,
        //               }}
        //               disabled
        //               value={formRef.getFieldValue("role")}
        //             />
        //             <Input
        //               style={{
        //                 color: "rgba(255,255,255,0.85)",
        //                 flex: 1,
        //               }}
        //               disabled
        //               value={item[element.optionLabel]}
        //             />
        //             <span
        //               className="clickable-text"
        //               style={{ cursor: "pointer" }}
        //               onClick={() => {
        //                 let formValues =
        //                   formRef.getFieldValue(element.name) || [];
        //                 formValues = [...formValues];
        //                 let dataState: any = [];
        //                 let data: any = {};

        //                 // 	if (filteredExtraData) {
        //                 // 		dataState = [...filteredExtraData[element.extraKey]];
        //                 // 		data = { ...filteredExtraData };
        //                 // 	} else {
        //                 // 		dataState = [...extraData[element.extraKey]];
        //                 // 		data = { ...extraData };
        //                 // 	}

        //                 // 	let filteredData = dataState;
        //                 // 	let foundData = extraData[element.extraKey].find(
        //                 // 		(_element: any) =>
        //                 // 			_element[element.optionKey] ===
        //                 // 			item[element.optionKey]
        //                 // 	);
        //                 // 	let foundIndex = formValues.findIndex(
        //                 // 		(_element: any) =>
        //                 // 			_element === item[element.optionKey]
        //                 // 	);
        //                 // 	if (foundIndex != -1 && foundData) {
        //                 // 		formValues.splice(foundIndex, 1);
        //                 // 		filteredData.push(foundData);
        //                 // 		filteredData = filteredData.sort((a: any, b: any) =>
        //                 // 			a[element.optionLabel].localeCompare(
        //                 // 				b[element.optionLabel]
        //                 // 			)
        //                 // 		);
        //                 // 		formRef.setFieldValue(element.name, formValues);
        //                 // 		data[element.extraKey] = [...filteredData];
        //                 // 	}
        //                 // 	setFilteredExtraData(data);
        //                 // 	setFormReset(false);
        //               }}
        //             >
        //               {GetAntIcon("close")}
        //             </span>
        //           </div>
        //         );
        //       })}
        //   </div>
        // );
        dataObject = filteredExtraData || dataTable;
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <Select
              autoFocus={index == 0 && !activeRowKey}
              allowClear
              mode="multiple"
              filterOption={filterOption}
              showSearch
              options={handleTemporaryOptions(element.case)}
              // mode={element.mode || undefined}
              onChange={(value: any) => {
                setFormReset(false);
                // console.log("value", value);
              }}
              style={{
                borderColor: element.borderColor || "#000000",
              }}
            ></Select>
          </Form.Item>
        );
      case "role-dynamic-form":
        const formChanged = () => {
          let formData = formRef.getFieldValue(element.name);
          let filteredData = dataTable[element.extraKey].filter(
            (data: any) =>
              data["name"].toLowerCase() !== "project manager" &&
              !formData.some((item: any) => item["role"] === data["name"])
          );

          let data = { ...dataTable };
          data[element.extraKey] = [...filteredData];
          setFilteredExtraData(data);
          setFormReset(false);
        };
        dataObject = filteredExtraData || dataTable;
        return (
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Create a form item that will use to select options as its value */}
            <div
              style={{
                gap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
                  gap: `${element.gap}`,
                }}
              >
                <Form.List name={element.name}>
                  {(fields, { add, remove }) => (
                    <>
                      {element.children.map((child: any, index: any) => (
                        <Form.Item
                          label={child.label}
                          rules={[
                            {
                              required: child.required,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            onDropdownVisibleChange={() => formChanged()}
                            autoFocus={index == 0 && !activeRowKey}
                            showSearch
                            disabled={
                              element.children.length - 1 === index &&
                              !selectedRole
                            }
                            options={handleSelectOptions(
                              child.optionKey,
                              child.optionLabel,
                              child.extraKey
                                ? dataObject[child.extraKey]
                                : null,
                              child.relayFrom
                            )}
                            autoClearSearchValue
                            tagRender={(props: any) => {
                              if (child.mode === "tags") {
                                let tag = dataTable?.find((child: any) => {
                                  return props.label == child.name;
                                });
                                if (tag) {
                                  return (
                                    <Tag color={tag.color}>{tag.name}</Tag>
                                  );
                                }
                              }
                              return <></>;
                            }}
                            value={
                              element.children.length - 1 === index
                                ? null
                                : selectedRole
                            }
                            onChange={(value: any, option: any) => {
                              if (element.children.length - 1 !== index) {
                                // option.label is assigned to store the label of the option which is the role name
                                let formValues = selectFieldValues;
                                formValues[child.name] = option.label;
                                setSelectFieldValues(formValues);
                                setSelectedRole(option.label);
                                // console.log("form values", formValues);
                              } else {
                                // value is pushed to store the email of the appointee
                                let formValues = selectFieldValues;
                                formValues[child.name] = value;
                                setSelectFieldValues(formValues);
                                setSelectedRole(null);
                              }

                              // The dynamic form will be added only if the all the fields are filled
                              if (
                                element.children.length ===
                                Object.keys(selectFieldValues).length
                              ) {
                                add(selectFieldValues);
                                setSelectFieldValues({});
                              }
                            }}
                          ></Select>
                        </Form.Item>
                      ))}
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          {element.children.map((child: any, index: any) =>
                            element.children.length - 1 !== index ? (
                              <>
                                <Form.Item
                                  {...restField}
                                  name={[name, child.name]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "",
                                    },
                                  ]}
                                >
                                  <Input
                                    style={{
                                      color: "rgba(255,255,255,0.85)",
                                      flex: 1,
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, child.name]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "",
                                    },
                                  ]}
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  <Select
                                    mode={child.mode || undefined}
                                    filterOption={filterOption}
                                    showSearch
                                    options={handleSelectOptions(
                                      child.optionKey,
                                      child.optionLabel,
                                      child.extraKey
                                        ? dataObject[child.extraKey]
                                        : null,
                                      child.relayFrom
                                    )}
                                  ></Select>
                                </Form.Item>
                                {/* Used Form.Item to use the same css settings as other form items. */}
                                <Form.Item>
                                  {/* Close Button */}
                                  <span
                                    className="clickable-text"
                                    style={{
                                      cursor: "pointer",
                                      margin: "auto",
                                      padding: "2px",
                                    }}
                                    onClick={() => {
                                      remove(name);
                                      formChanged();
                                    }}
                                  >
                                    {GetAntIcon("close")}
                                  </span>
                                </Form.Item>
                              </div>
                            )
                          )}
                        </>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </div>
        );
      case "select-temporary":
        dataObject = filteredExtraData || dataTable;
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <Select
              autoFocus={index == 0 && !activeRowKey}
              allowClear
              filterOption={filterOption}
              showSearch
              options={handleTemporaryOptions(element.case, element.relay)}
              mode={element.mode || undefined}
              // defaultValue={
              //   element.default
              //     ? element.mode
              //       ? handleTemporaryOptions(element.case).map(
              //           (item: any) => item.value
              //         )
              //       : handleTemporaryOptions(element.case).find(
              //           (item: any) => item.value === element.default
              //         )
              //     : undefined
              // }
              onChange={(value: any) => {
                setFormReset(false);
              }}
              style={{
                borderColor: element.borderColor || "#000000",
              }}
            ></Select>
          </Form.Item>
        );
      case "select-status":
        dataObject = filteredExtraData || dataTable;
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <Select
              autoFocus={index == 0 && !activeRowKey}
              allowClear
              filterOption={filterOption}
              showSearch
              options={handleTemporaryOptions(element.case)}
              mode={element.mode || undefined}
              // onChange={onStatusChange}
              style={{
                borderColor: element.borderColor || "#000000",
              }}
            ></Select>
          </Form.Item>
        );
      case "select-tree":
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <TreeSelect
              allowClear
              multiple
              treeDefaultExpandAll
              showSearch={false}
              showCheckedStrategy={SHOW_PARENT}
              treeCheckable={true} // Enable checkboxes
              treeData={companyoptions}
              defaultValue={element.default ? element.default : undefined}
              listHeight={256}
              style={{
                borderColor: element.borderColor || "#000000",
              }}
              disabled={formRef.getFieldsValue().report_date === undefined}
            ></TreeSelect>
          </Form.Item>
        );
      case "select-tree-product":
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <TreeSelect
              allowClear
              multiple
              treeDefaultExpandAll
              showSearch={false}
              showCheckedStrategy={SHOW_PARENT}
              treeCheckable={true} // Enable checkboxes
              treeData={productoptions}
              defaultValue={element.default ? element.default : undefined}
              onChange={onChange}
              listHeight={256}
              style={{
                borderColor: element.borderColor || "#000000",
              }}
              disabled={formRef.getFieldsValue().report_date === undefined}
            ></TreeSelect>
          </Form.Item>
        );
      case "select-relay":
        dataObject = filteredExtraData || dataTable;
        return (
          <Form.Item
            label={element.label}
            name={element.name}
            rules={[{ required: element.required, message: "" }]}
          >
            <Select
              autoFocus={index == 0 && !activeRowKey}
              allowClear
              // filterOption={filterOption}
              showSearch={false}
              options={productoptions}
              mode={element.mode || undefined}
              onChange={(value: any) => {
                setFormReset(false);
              }}
              style={{
                borderColor: element.borderColor || "#000000",
              }}
            ></Select>
          </Form.Item>
        );
      default:
        return <Input disabled></Input>;
    }
  };

  const initialValues = getFormOptionList(tabKey).reduce(
    (acc: any, field: any) => {
      if (field.default !== undefined) {
        if (field.type === "select-temporary" && field.mode === "multiple") {
          acc[field.name] = handleTemporaryOptions(field.case).map(
            (item: any) => item.value
          );
        } else {
          acc[field.name] = field.default;
        }
      }
      return acc;
    },
    {}
  );

  // Populate form with data from the form list
  const setForm = () => {
    const formList = getFormOptionList(tabKey);
    if (formList) {
      return (
        <Form
          layout={"vertical"}
          form={formRef}
          requiredMark="optional"
          initialValues={initialValues}
          style={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "0 15px",
          }}
          onChange={handleFormChange}
          onFinish={(values: any) => {
            // console.log("productoption", productoptions);
            console.log("Send value", values);
          }}
        >
          {formList.map((element: any, index: any) =>
            setInputType(element, index)
          )}
        </Form>
      );
    } else {
      return null;
    }
  };

  return setForm();
};

export default CustomForm;
