import { Datum, Layout, PlotData } from "plotly.js";
import { useMemo } from "react";
import Plot from "react-plotly.js";

type PlotlyAgingBucketsProps = {
  x?: Datum[];
  y?: number[];
  title?: string;
  tickStep?: number;
  height?: number;
};

const PlotlyAgingBuckets = ({
  title = "Title",
  x,
  y,
  tickStep = 10000000,
  height,
}: PlotlyAgingBucketsProps) => {
  const headers = useMemo(() => ["", ...(x || [])], [x]);

  const cellValues: string[][] = useMemo(() => {
    return Array.from({ length: headers.length }, (_, i) => {
      if (i === 0) {
        return ["Amount"];
      }
      return [`RM${(y?.[i - 1] as number)?.toLocaleString() || "0"}`];
    });
  }, [headers, y]);

  return (
    <Plot
      data={[
        {
          type: "bar",
          x,
          y,
          name: "Amount",
        },
        {
          type: "table",

          header: {
            values: headers,
            fill: {
              color: headers.map((header) => {
                return !header ? "transparent" : "white";
              }),
            },
            line: {
              width: headers.map((header) => {
                return !header ? 0 : 1;
              }),
            },
          },
          cells: {
            values: cellValues,
            align: ["left", "center"],
          },
          domain: { x: [0.04, 1], y: [0.0, 0.3] },
        } as Partial<PlotData>,
      ]}
      layout={
        {
          height,
          bargap: 0.5,
          showlegend: true,
          legend: {
            itemclick: false,
            itemdoubleclick: false,
            // x: -0.2,
            // y: 0.1,
            x: -0.35,
            y: 0.15,
            // x: 0.04, // Position the legend in the middle of the plot
            // y: 0.19, // Adjust the y position as needed
            // xanchor: "center", // Anchor the legend to the center
            // yanchor: "middle", // Anchor the legend to the middle
          },
          dragmode: false,
          title: {
            text: title,
            font: {
              family: "PP Neue Machina",
              size: 16,
            },
          },
          yaxis: {
            tickformat: ",",
            domain: [0.3, 1],
            dtick: tickStep, // Tick interval
            ticklabelstandoff: 15, // Distance between tick labels and the axis
          },
          xaxis: {
            showticklabels: false,
            domain: [0.2, 1],
          },
          grid: {
            pattern: "independent",
            rows: 2,
            columns: 1,
          },
          font: {
            family: "Motiva Sans",
          },
        } as Partial<Layout>
      }
      config={{
        displaylogo: false, // Remove the Plotly logo\
        responsive: true,
        staticPlot: true,
      }}
    />
  );
};

export default PlotlyAgingBuckets;
