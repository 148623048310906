import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Space,
  Tabs,
  message,
  Upload,
  Spin,
  Progress,
  Modal,
  Button,
  UploadFile,
} from "antd";
import type { UploadProps } from "antd";
import LookupManager from "./LookupManager";
import { setContainer } from "../utils/containers";
import { HomeContext } from "./Home";
import { InboxOutlined, InfoCircleFilled } from "@ant-design/icons";
import {
  addExcelItem,
  updateExcelFileItem,
  getExcelListByField,
} from "../services/api-server/excel_file";
import { deleteFile, uploadFile } from "../services/api-server/filetransfer";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { socket } from "../utils/socket";
import { blake2b } from "blakejs";
import { permissions_access } from "../utils/_exports";

const { Dragger } = Upload;

function GenericTabContainer(props: any) {
  const role = props.params?.userRole[0];
  const context: any = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [duplicateFileModalVisible, setDuplicateFileModalVisible] =
    useState(false);
  const [tabkey, setTabKey] = useState("lookup-manager");
  const [fileToUpload, setFileToUpload]: any = useState(null);
  const [filelist, setFileList]: any = useState([]);

  const changeContent = () => {
    switch (tabkey) {
      case "lookup-manager":
        return (
          <LookupManager
            tabKey={"excel"}
            subTabKey={"Excel file"}
            containerTitle={""}
          />
        );
      default:
        return <></>;
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Hide modal on cancel or close
  };

  const handleCloseFileConfirmation = () => {
    setDuplicateFileModalVisible(false);
  };

  const handleConfirmReupload = () => {
    setDuplicateFileModalVisible(false);
    uploadExcelFile(fileToUpload);
  };

  const handleMultipleUploads = async (fileList: File[]) => {
    console.log("fileList", fileList);
    // for (const file of fileList) {
    //   console.log("Processing file:", file.name);

    //   const find_duplicate = {
    //     blake_hash: hashString(file.name),
    //   };

    //   const data: any = await getExcelListByField(find_duplicate);

    //   if (data.length > 0) {
    //     setFileToUpload(file);
    //     setDuplicateFileModalVisible(true);
    //   } else {
    //     await uploadExcelFile(file);
    //   }
    // }
  };

  const handleUpload = async (info: any) => {
    // console.log("info", info);
    const files = info.fileList;
    // console.log("files", files);
    // const filename = info.file.name;
    // const find_duplicate = {
    //   blake_hash: hashString(filename),
    // };

    // const data: any = await getExcelListByField(find_duplicate);
    // if (data.length > 0) {
    //   setFileToUpload(info.file);
    //   setDuplicateFileModalVisible(true);
    // } else {
    //   uploadExcelFile(info.file);
    // }
    if (permissions_access?.includes("report upload raw data")) {
      const filename = info.file.name;
      const find_duplicate = {
        blake_hash: hashString(filename),
      };

      const data: any = await getExcelListByField(find_duplicate);
      if (data.length > 0) {
        setFileToUpload(info.file);
        setDuplicateFileModalVisible(true);
      } else {
        uploadExcelFile(info.file);
      }
    } else {
      message.error(`No permission to upload raw data.`);
    }
  };

  const uploadExcelFile = (file: any) => {
    const UploadformData = new FormData();
    const filename = file.name;
    const imgUUID = uuidv4() + "." + filename.split(".").pop();
    UploadformData.append("file", file);
    UploadformData.append("filename", filename);
    UploadformData.append("uuid", imgUUID);
    UploadformData.append("size", file.size);
    UploadformData.append("upload_date", moment().toISOString());
    setLoading(true);
    setUploadPercent(0); // Reset progress bar
    setIsModalVisible(true);
    // Simulate file upload and progress
    const fakeUpload = setInterval(() => {
      setUploadPercent((prev) => {
        const newPercent = prev + 1;
        if (newPercent >= 100) {
          clearInterval(fakeUpload);
        }
        return newPercent;
      });
    }, 3000); // Simulate upload progress
    uploadFile(UploadformData, {
      upload_path: "../katimas_upload_excel",
    })
      .then((data: any) => {
        setLoading(false);
        setUploadPercent(100); // Upload complete
        message.success(
          `${data?.data?.results?.filename} has been successfully uploaded.`
        );
        socket.emit(
          "excel_datatable_update",
          `Hello from React frontend! ${socket.id}`
        );
      })
      .catch((error) => {
        if (error?.response?.data?.detail === "No date part in the filename.") {
          message.error(
            `Upload failed. Please ensure the file name includes the date in [YYYY-MM] format`
          );
        } else {
          message.error(`Excel upload failed. Please try again.`);
        }
        setLoading(false);
        setUploadPercent(0); // Reset on error
      })
      .finally(() => {
        setIsModalVisible(false); // Hide modal when upload finishes
      });
  };

  const hashString = (input: string) => {
    const inputBuffer = new TextEncoder().encode(input); // Convert string to Uint8Array
    const hashBuffer = blake2b(inputBuffer); // Generate Blake2b hash
    return Array.from(hashBuffer)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join(""); // Convert hash to hex string
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    // console.log("newFileList", newFileList);
    setFileList(newFileList);
  };

  return (
    <div className="generic-container" style={{ gap: "20px" }}>
      <Space direction="vertical">
        <span
          style={{
            fontSize: "24px",
            fontFamily: "PP Neue Machina",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {props.params.propTitle}
        </span>
        <span style={{ fontSize: "14px" }}>
          Upload your Excel files here to easily generate detailed reports. File
          names must include the date [YYYY-MM], e.g., CLI [2024-09].xlsx.
        </span>
      </Space>
      <Upload.Dragger
        fileList={filelist}
        name="file"
        // multiple
        accept=".xlsx, .xls"
        customRequest={handleUpload}
        onChange={handleChange}
        // customRequest={(options) => handleUpload(options)}
        // customRequest={async (options: any) => {
        //   console.log("options", options);
        //   await handleMultipleUploads(options.fileList);
        // }}
        onDrop={async (e) => {
          // console.log("Dropped files", e.dataTransfer.files);
          const files = e.dataTransfer.files;
        }}
        itemRender={() => null} // Hide the upload item list
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text" style={{ fontSize: "14px" }}>
          Drag file to this area to upload or{" "}
          <span style={{ color: "#7F61FF" }}>upload file</span>
        </p>
        <p className="ant-upload-hint" style={{ fontSize: "14px" }}>
          Supports both single and bulk uploads, accepting only Excel files
          (.xlsx format).
        </p>
      </Upload.Dragger>
      <Modal
        footer={null}
        title={
          <div
            style={{
              padding: "15px",
              backgroundColor: "#2C378E",
              color: "white",
              minHeight: "25px",
              maxHeight: "25px",
            }}
          >
            File upload
          </div>
        }
        open={isModalVisible}
        // onCancel={handleCancel}
        centered
        closable={false} // Disable closing during upload
        maskClosable={false} // Disable closing on outside click
        // footer={[
        //   <div style={{ padding: "16px" }}>
        //     <Button
        //       key="back"
        //       onClick={handleStopUpload}
        //       style={{ width: "100%" }}
        //     >
        //       Cancel
        //     </Button>
        //   </div>,
        // ]}
      >
        <p
          style={{
            display: "inline",
            marginRight: "10px",
            marginLeft: "15px",
          }}
        >
          Uploading {uploadPercent}% complete...
        </p>
        <Progress
          style={{ marginRight: "100px, 0", padding: "0 20px 0 20px" }}
          percent={uploadPercent}
          status={uploadPercent < 100 ? "active" : "success"}
        />
      </Modal>
      <Modal
        centered
        width={400}
        maskClosable={false}
        open={duplicateFileModalVisible}
        title={
          <div
            style={{
              padding: "10px 0px 0px 10px",
              // backgroundColor: "#2C378E",
              // color: "white",
              // minHeight: "25px",
              // maxHeight: "25px",
            }}
          >
            File already exist
          </div>
        }
        closable={false}
        className="modal-report"
        footer={[
          <div style={{ padding: "16px" }}>
            <Button
              key="back"
              onClick={handleCloseFileConfirmation}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              style={{
                // backgroundColor: "#F7B01F",
                color: "white",
                borderWidth: " 1px, 0px, 1px, 1px",
                borderStyle: "solid",
                borderColor: "#7f61ff",
              }}
              className=" normal-login-button"
              onClick={handleConfirmReupload}
            >
              Continue upload
            </Button>
          </div>,
        ]}
      >
        <div style={{ display: "flex" }}>
          <InfoCircleFilled
            style={{
              color: "#FAAD14",
              paddingLeft: "10px",
              borderColor: "red",
              marginBottom: "25px",
            }}
          />
          <p
            style={{
              display: "inline",
              marginRight: "10px",
              marginLeft: "15px",
            }}
          >
            Uploading this file will replace the existing data in the database.
            Do you want to proceed with the upload?
          </p>
        </div>
      </Modal>
      {changeContent()}
    </div>
  );
}

export default GenericTabContainer;
